import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

export const maxContentWidth = 700;

export const RootView = styled('div', { label: 'ContractRoot' })(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: theme.spacing(2),
	alignItems: 'center',
	justifyContent: 'center',
	color: 'white',
}));

export const CenterContent = styled('div')(({ theme }) => ({
	maxWidth: maxContentWidth,
	width: '100%',
	marginBottom: '1rem',
	padding: theme.spacing(2),
	paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
}));

export const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	marginTop: theme.spacing(1),
	alignItems: 'center',
	justifyContent: 'center',
}));

export const MintRow = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(3),
	width: '100%',
}));

export const ActionButton = styled(Button, { label: 'ActionButton' })(({ theme }) => ({
	borderRadius: 10,
	color: 'black',
	width: '100%',
	fontSize: '1.8em',
	fontWeight: 900,
	fontFamily: 'ArchitectsDaughter',
	background: 'white',
	boxShadow: '3px 4px 0px 0px black',
    lineHeight: 1.3,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
	padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
	//backdropFilter: 'brightness(0.8)',
	background: '#8bafb4',
	borderRadius: 14,
	width: 'fit-content',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontStyle: 'normal',
}));

export const NumberText = styled(InfoText)(({ theme }) => ({
	paddingLeft: theme.spacing(4),
	paddingRight: theme.spacing(4),
}));

export const Section = styled('section')(({ theme }) => ({
	display: 'flex',
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	background: `white`,
	padding: theme.spacing(2),
	paddingLeft: 0,
	paddingRight: 0,
	overflow: 'auto',

	// Smaller screens need to display as column
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column',
		paddingLeft: theme.spacing(6),
		paddingRight: theme.spacing(6),
	},
}));
