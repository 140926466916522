import { logEvent } from 'firebase/analytics';
import { useEffect, useState } from 'react';
import { firebaseAnalytics } from './analytics';

export const goToLink = (url: string) => {
	window.open(url, '_blank')?.focus();
};

export const goToDiscord = () => {
	logEvent(firebaseAnalytics, `social`, { type: 'discord' });
	goToLink('https://discord.gg/38bdFxnj76');
};

export const goToTwitter = () => {
	logEvent(firebaseAnalytics, `social`, { type: 'twitter' });
	goToLink('https://twitter.com/GirlVibes_NFT');
};

export const goToTwitterHandle = (handle: string) => {
	logEvent(firebaseAnalytics, `social`, { type: 'twitter', handle });
	goToLink(`https://twitter.com/${handle}`);
};

export const goToOpenSea = () => {
	logEvent(firebaseAnalytics, `social`, { type: 'openSea' });
	goToLink('https://opensea.io/collection/girlvibes-beach-edition');
};

export const useWindowResize = () => {
	const getWindowDimensions = () => {
		const { innerWidth: width, innerHeight: height } = window;
		return { width, height };
	};

	const [dim, setDim] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setDim(getWindowDimensions());
		}

		//const debouncedResize = debounce(handleResize, 700);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return dim;
};

export const loadImage = (src: string) => {
	return new Promise<HTMLImageElement>((resolve, reject) => {
		let img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = src;
	});
};

