import { CircularProgress, IconButton, styled, Typography } from '@mui/material';
import { useContract } from '../../providers/ContractProvider';
import {
	RootView,
	InfoText,
	ActionButton,
	MintRow,
	NumberText,
} from '../../components/Common';
import { Fragment, useMemo, useState } from 'react';
import { useWallet } from '../../providers/WalletProvider';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EthLogo from '../../img/EthLogo.svg';
import MintSpeech from '../../img/SpeachBubble.png';
import Heading from './Heading';
import { goToDiscord } from '../../util';
import { UserOwnedGallery } from '../../components/gallery/UserGallery';
import { firebaseAnalytics } from '../../analytics';
import { logEvent } from 'firebase/analytics';
import { format } from 'd3-format';

const ContractViewRoot = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	color: 'white',
}));

const QuantityAdjustGroup = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
}));

const QuantityAdjust = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const EthIcon = styled('img')(({ theme }) => ({
	marginLeft: theme.spacing(1),
}));

const ArrowLeft = styled(ArrowLeftIcon)(({ theme }) => ({
	fontSize: '1.6em',
	color: 'white',
}));

const ArrowRight = styled(ArrowRightIcon)(({ theme }) => ({
	fontSize: '1.6em',
	color: 'white',
}));

const MintNowImg = styled('img')(({ theme }) => ({
	// right: 0,
	// top: '-75%',
	// left: '70%',
	position: 'absolute',
	width: '25%',

	[theme.breakpoints.up('md')]: {
		transform: 'translate(calc(50% + 180px), -20%)',
	},
	[theme.breakpoints.down('md')]: {
		transform: 'translate(calc(50% + 160px), -20%)',
	},
	[theme.breakpoints.down('sm')]: {
		transform: 'translate(calc(50% + 120px), -20%)',
	},
}));

const MintNowRow = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	position: 'relative',
	color: 'white',
}));

export const ContractView = () => {
	const {
		totalMinted,
		mintPrice,
		maxSupply,
		isAllowListed,
		requestInProgress,
		contractValues,
		userOwnedTokens,
		mint,
	} = useContract();
	const { connected, showConnect, walletAddress } = useWallet();
	const [quantityToMint, setQuantityToMint] = useState(1);

	const isPreRelease = process.env.REACT_APP_MODE === 'PRE_RELEASE';

	const buttonEnabled = useMemo(() => {
		// If not connected we'll be able to show connect popup instead.
		if (!connected || !walletAddress) return true;

		// Can mint.
		return true;
	}, [connected, walletAddress]);

	const maxMint = useMemo(() => {
		return contractValues.maxMintAmount;
	}, [contractValues.maxMintAmount]);

	const preSaleOver = useMemo(() => {
		if (maxSupply === 0 && totalMinted === 0) return false;

		return maxSupply === totalMinted;
	}, [maxSupply, totalMinted]);

	const incrementPurchaseCount = () => {
		setQuantityToMint((prev) => {
			if (prev + 1 > maxMint) {
				return prev;
			}
			return prev + 1;
		});
	};

	const decrementPurchaseCount = () => {
		setQuantityToMint((prev) => {
			if (prev - 1 < 1) {
				return prev;
			}
			return prev - 1;
		});
	};

	const onClickMint = () => {
		logEvent(firebaseAnalytics, 'click_mint');
		if (isPreRelease) {
			// Todo: Go to discord.
			goToDiscord();
			return;
		}

		if (preSaleOver) {
			return;
		}
		if (!connected || !walletAddress) {
			showConnect();
		} else {
			if (contractValues.allowListOnly && !isAllowListed) {
				goToDiscord();
				return;
			}
			if (!contractValues.mintingActive) {
				goToDiscord();
			}
			mint(quantityToMint);
		}
	};

	const buttonText = useMemo(() => {
		if (isPreRelease) {
			return 'Join Discord';
		}
		if (!connected || !walletAddress) {
			return 'Connect Wallet';
		}
		if (contractValues.allowListOnly && !isAllowListed) {
			return 'Presale Only';
		}
		if (!contractValues.mintingActive) {
			return 'Sale Inactive';
		}
		if (preSaleOver) {
			return 'Sale Finished';
		}
		return 'Mint Now!';
	}, [
		isPreRelease,
		connected,
		walletAddress,
		contractValues.mintingActive,
		contractValues.allowListOnly,
		isAllowListed,
		preSaleOver,
	]);

	const totalPrice = useMemo(() => {
		return (mintPrice * quantityToMint).toFixed(3);
	}, [mintPrice, quantityToMint]);

	const noWallet = useMemo(() => {
		return !connected || !walletAddress;
	}, [connected, walletAddress]);

	return (
		<ContractViewRoot>
			<MintNowRow>
				<Heading text="Sold Out" />
				<MintNowImg src={MintSpeech} />
			</MintNowRow>

			{userOwnedTokens > 0 && walletAddress && (
				<Typography variant="h6" textAlign={'center'} sx={{ margin: 1 }}>
					{`Congratulations you own ${userOwnedTokens} #GirlVibes!!`}
				</Typography>
			)}
			<UserOwnedGallery />
			<RootView>
				{requestInProgress && <CircularProgress color="primary" />}

				{!walletAddress && (
					<Typography
						variant="h6"
						textAlign={'center'}
						sx={{ marginBottom: 2 }}
					>
						{`GirlVibes has officially minted out! But don't worry! You can still purchase one on the secondary market, visit OpenSea`}
					</Typography>
				)}

				{!requestInProgress && !isPreRelease && (
					<Fragment>
						<MintRow>
							<Typography variant="h6">Minted</Typography>
							<InfoText variant="h6">{`${format(',')(
								totalMinted
							)} / ${format(',')(maxSupply)}`}</InfoText>
						</MintRow>

						{walletAddress && (
							<MintRow>
								<Typography variant="h6">Cost (Excluding gas)</Typography>
								<InfoText variant="h6">
									{`${totalPrice}`}
									<EthIcon
										width={26}
										height={26}
										src={EthLogo}
										alt="eth"
									/>
								</InfoText>
							</MintRow>
						)}
						{walletAddress && (
							<MintRow>
								<QuantityAdjustGroup>
									<Typography variant="h6">
										Purchase Quantity
									</Typography>
									<QuantityAdjust>
										<IconButton
											onClick={decrementPurchaseCount}
											size="large"
											disabled={noWallet}
										>
											<ArrowLeft />
										</IconButton>
										<NumberText variant="h6">
											{quantityToMint}
										</NumberText>
										<IconButton
											onClick={incrementPurchaseCount}
											size="large"
											disabled={noWallet}
										>
											<ArrowRight />
										</IconButton>
									</QuantityAdjust>
								</QuantityAdjustGroup>
							</MintRow>
						)}
					</Fragment>
				)}

				<div>
					<ActionButton
						variant="contained"
						onClick={onClickMint}
						sx={{ marginTop: isPreRelease ? 2 : 0 }}
						disabled={!buttonEnabled}
					>
						{buttonText}
					</ActionButton>
				</div>
			</RootView>
		</ContractViewRoot>
	);
};

