import { styled } from '@mui/system';
import OpenSeaLogo from '../img/OpenSeaLogo.svg';
import DiscordLogo from '../img/DiscordLogo.svg';
import TwitterLogo from '../img/TwitterLogo.svg';
import { IconButton, Stack } from '@mui/material';
import { goToDiscord, goToOpenSea, goToTwitter } from '../util';

const Logo = styled('img')(({ theme }) => ({
	width: 32,
	height: 32,
}));

const IButton = styled(IconButton)(({ theme }) => ({
	transition: 'all .2s ease-in-out',
	'&&:hover': {
		transform: 'scale(1.1)',
	},
}));

const SocialLinks: React.FC = () => {
	return (
		<Stack direction="row" spacing={1} sx={{ marginRight: 2 }}>
			<IButton size="small">
				<Logo src={OpenSeaLogo} alt="View on OpenSea" onClick={goToOpenSea} />
			</IButton>
			<IButton size="small" onClick={goToTwitter}>
				<Logo src={TwitterLogo} alt="Twitter" />
			</IButton>
			<IButton size="small" onClick={goToDiscord}>
				<Logo src={DiscordLogo} alt="Discord community" />
			</IButton>
		</Stack>
	);
};
export default SocialLinks;

