import { styled } from '@mui/material/styles';
import ContractProvider from './providers/ContractProvider';
import WalletProvider from './providers/WalletProvider';

import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { HomeScreen } from './screens/home/HomeScreen';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { createRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { Route, BrowserRouter, Routes } from 'react-router-dom';
import VibesScreen from './screens/vibes/VibesScreen';
import Header from './components/Header';

function getLibrary(provider: any) {
	return new Web3(provider);
}

const AppContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'column',
}));

const ScreenView = styled('div', { label: 'Home' })(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'relative',
	width: '100%',
}));

const MintingContentBG1 = styled('div', { label: 'Background' })(({ theme }) => ({
	position: 'absolute',
	left: 0,
	bottom: 0,
	right: 0,
	top: 0,
	height: '50%',
	background: `linear-gradient(to top right, hsl(173deg 85% 87%) 50%, rgb(255, 100, 180) 100%)`,
	zIndex: -1,
}));

const MintingContentBG2 = styled('div', { label: 'Background2' })(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: '50%',
	height: '50%',
	background:
		'linear-gradient(to bottom, hsl(173deg 85% 87%) 50%, hsl(176deg 46% 64%))',
	zIndex: -1,
}));

const ScreenContainer: React.FC = ({ children }) => {
	return (
		<ScreenView sx={{ paddingTop: 14 }}>
			<MintingContentBG1 />
			<MintingContentBG2 />
			{children}
		</ScreenView>
	);
};

function App() {
	const notistackRef = createRef<any>();
	const onClickDismiss = (key: SnackbarKey) => () => {
		notistackRef?.current.closeSnackbar(key);
	};

	return (
		<SnackbarProvider
			maxSnack={1}
			ref={notistackRef}
			action={(key) => (
				<IconButton onClick={onClickDismiss(key)}>
					<CloseIcon />
				</IconButton>
			)}
		>
			<Web3ReactProvider getLibrary={getLibrary}>
				<WalletProvider>
					<ContractProvider>
						<BrowserRouter>
							<AppContainer>
								<Header />

								<ScreenContainer>
									<Routes>
										<Route
											path="/goodvibes"
											element={<VibesScreen />}
										/>
										<Route path="/" element={<HomeScreen />} />
									</Routes>
								</ScreenContainer>
							</AppContainer>
						</BrowserRouter>
					</ContractProvider>
				</WalletProvider>
			</Web3ReactProvider>
		</SnackbarProvider>
	);
}

export default App;

