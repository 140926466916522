import { red } from '@mui/material/colors';
import {
	createTheme,
	responsiveFontSizes,
	ThemeOptions,
	ThemeProvider,
} from '@mui/material/styles';

const themeSettings: ThemeOptions = {
	typography: {
		fontFamily: `'DM Sans', 'ArchitectsDaughter', sans-serif`,
		fontSize: 16,
		allVariants: {
			fontStyle: 'italic',
			fontWeight: 400,
		},
		button: {
			textTransform: 'none',
			fontWeight: 900,
			//fontSize: '1.4em'
		},
	},
	palette: {
		mode: 'dark',
		primary: {
			main: '#1cf5dc',
		},
		secondary: {
			main: '#19857b',
		},
		error: {
			main: red.A400,
		},
		action: {
			disabledBackground: '#1cf5dc',
			disabled: 'black',
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
				disableTouchRipple: true,
			},
		},
	},
};

// A custom theme for this app
const theme = responsiveFontSizes(createTheme({ ...themeSettings }));

const lightTheme = responsiveFontSizes(
	createTheme({
		...themeSettings,
		...{
			palette: { ...themeSettings.palette, mode: 'light' },
		},
	})
);

export const LightThemeProvider: React.FC = ({ children }) => {
	return <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>;
};

export default theme;
