// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyCfHyupXZo2yV8Gob40kz9RBXtDHftgYvw',
	authDomain: 'girlvibes-analytics.firebaseapp.com',
	projectId: 'girlvibes-analytics',
	storageBucket: 'girlvibes-analytics.appspot.com',
	messagingSenderId: '194021320357',
	appId: '1:194021320357:web:c964e312beccf2f9d5cf4c',
	measurementId: 'G-RVHXRQQKGD',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
