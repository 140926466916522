import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { Connectors, ConnectType } from '../connectors/connectors';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useSnackbar } from 'notistack';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import { NoMetaMaskExtension } from '../components/warnings/WarningNotice';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../analytics';

interface WalletContext {
	/**
	 * The Address of the users wallet. Null if no account / wallet
	 */
	walletAddress?: string;
	/**
	 * The initialised Web3 library, depending on chosen method, connects using Infura if none selected.
	 */
	web3?: Web3;
	/**
	 * Connects to a specified wallet can async wait for connection to be successful.
	 */
	connect: (connectType: ConnectType) => Promise<boolean>;
	/**
	 * Disconnects the wallet.
	 */
	disconnect: () => void;

	/**
	 * Function to enable show connect popup. This belongs here so any children have access to showing the connect.
	 */
	showConnect: () => void;
	/**
	 * Closes show connect popup.
	 */
	closeConnect: () => void;
	/**
	 * Context state variables
	 */
	connected: boolean;
	connectorType?: ConnectType;
	connectShow: boolean;
}

const walletContext = React.createContext<WalletContext>({
	connect: () => Promise.resolve(false),
	disconnect: () => {},
	closeConnect: () => {},
	showConnect: () => {},
	connected: false,
	connectShow: false,
});

/**
 * Provides access to wallet connection methods and state around the currently connected wallet / user.
 */
const WalletProvider: React.FC = ({ children }) => {
	const [walletAddress, setWalletAddress] = useState<string | undefined>();
	const { active, account, library, activate, deactivate, error, chainId } =
		useWeb3React();
	const [connectorType, setConnectorType] = useState<ConnectType>();
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const acceptedChain = useMemo(
		() =>
			process.env.REACT_APP_CHAIN_ID
				? parseInt(process.env.REACT_APP_CHAIN_ID)
				: undefined,
		[]
	);

	const connectInfura = useCallback(() => {
		// Only connect to infura if we are ready to go as it will save on api methods.
		if (process.env.REACT_APP_MODE !== 'PRE_RELEASE') {
			activate(Connectors[ConnectType.Infura], undefined, true)
				.then(() => {})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [activate]);

	const disconnect = useCallback(() => {
		setConnectorType(ConnectType.NotConnected);
		deactivate();
	}, [deactivate]);

	useEffect(() => {
		if (
			error instanceof UnsupportedChainIdError ||
			(chainId && chainId !== acceptedChain)
		) {
			enqueueSnackbar(
				'Your wallet is connected to an unsupported network. Please connect to the Etherium network',
				{
					variant: 'warning',
					preventDuplicate: true,
					autoHideDuration: 7000,
				}
			);
			disconnect();
			connectInfura();
		}
	}, [disconnect, enqueueSnackbar, error, chainId, acceptedChain, connectInfura]);

	const showConnect = useCallback(() => setOpen(true), []);
	const closeConnect = useCallback(() => setOpen(false), []);

	// Connect to remote RPC first when no wallet is connected.
	useEffect(() => {
		if (!library) {
			// Only connect to infura if we are ready to go as it will save on api methods.
			connectInfura();
		}
	}, [connectInfura, library]);

	const connect = useCallback(
		(ct: ConnectType) => {
			logEvent(firebaseAnalytics, `connect_wallet`, { wallet: ct });
			return new Promise<boolean>((resolve, reject) => {
				if (ct !== ConnectType.NotConnected) {
					const con = Connectors[ct];
					activate(con, undefined, true)
						.then(() => {
							setConnectorType(ct);
							resolve(true);
						})
						.catch((error) => {
							console.log(error);
							if (error instanceof UnsupportedChainIdError) {
								enqueueSnackbar(
									'Your wallet is connected to an unsupported network. Please connect to the Etherium network',
									{
										variant: 'warning',
										preventDuplicate: true,
										autoHideDuration: 7000,
									}
								);
							}
							if (error instanceof NoEthereumProviderError) {
								enqueueSnackbar(<NoMetaMaskExtension />, {
									variant: 'warning',
									preventDuplicate: true,
									autoHideDuration: 7000,
								});
							}
							// Rejected the connection request wallet connect needs to reset.
							if (con && con instanceof WalletConnectConnector) {
								con.walletConnectProvider = undefined;
							}

							resolve(false);
						});
				}
			});
		},
		[activate, enqueueSnackbar]
	);

	useEffect(() => {
		setWalletAddress(account === null ? undefined : account);
	}, [account]);

	const api = useMemo<WalletContext>(() => {
		return {
			connected: active,
			connectShow: open,
			connect,
			disconnect,
			closeConnect,
			showConnect,
			web3: library,
			walletAddress,
			connectorType,
		};
	}, [
		active,
		open,
		connect,
		disconnect,
		closeConnect,
		showConnect,
		library,
		walletAddress,
		connectorType,
	]);

	return <walletContext.Provider value={api}>{children}</walletContext.Provider>;
};

export const useWallet = () => React.useContext(walletContext);

export default WalletProvider;

