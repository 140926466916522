import { useEffect, useMemo, useState } from 'react';
import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { useContract } from '../../providers/ContractProvider';
import { BackgroundElements } from './BackgroundData';
import { GalleryProvider, ItemImage } from './GalleryProvider';

export interface ImageDetail {
	name: string;
	unlocked: boolean;
}

interface BackgroundSelectorProps {
	onSelect: (background: ImageDetail) => void;
}

const defaultSelected = 'Evening Glow';

const sortImages = (a: ImageDetail, b: ImageDetail) => {
	return Number(a.unlocked) - Number(b.unlocked);
};

export const BackgroundSelector: React.FC<BackgroundSelectorProps> = ({ onSelect }) => {
	const { userNFTList } = useContract();

	const [imageList, setImageList] = useState<ImageDetail[]>([]);
	const [selectedImage, setSelectedImage] = useState(defaultSelected);

	useEffect(() => {
		const backgrounds = new Set<string>();
		userNFTList.forEach((nft) => {
			const background = nft.metadata.traits.find(
				(t) => t.trait_type === 'Background'
			)?.value;
			if (background) {
				backgrounds.add(background);
			}
		});
		const available = Array.from(backgrounds);
		setImageList((prev) => {
			return prev
				.map((v) => {
					const be = BackgroundElements.find((be) => be.path === v.name);
					const unlocked = be?.unlocked
						? be.unlocked
						: available.includes(v.name);

					return {
						...v,
						unlocked,
					};
				})
				.sort(sortImages);
		});
	}, [userNFTList]);

	const onSelectImage = (detail: ImageDetail) => (e: any) => {
		setSelectedImage(detail.name);
		onSelect(detail);
	};

	useEffect(() => {
		const elements = BackgroundElements.map((f) => {
			return { name: f.path, path: f.path, unlocked: f.unlocked };
		}).sort(sortImages);
		setImageList(elements);
		const select = elements.find((e) => e.name === defaultSelected);
		if (select) {
			onSelect(select);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const initialSlide = useMemo(() => {
        const index = imageList.findIndex((i) => i.name === defaultSelected);
        if (index === -1) return 0;
        return index;
    }, [imageList]);

	return (
		<GalleryProvider
			numberOfSlides={imageList.length}
			slidesPerView="auto"
			//maxWidth={604}
			paginationClassName="background-selector-pagination"
			centeredSlides={true}
			sx={{ padding: 2, background: 'white' }}
			pagination={false}
            initialSlide={initialSlide}
		>
			{imageList.map((image) => {
				const isSelected = selectedImage === image.name;

				const canSelect = image.unlocked;

				return (
					<SwiperSlide
						key={image.name}
						style={{
							width: 100,
							height: 100,
							border: isSelected ? '2px solid green' : undefined,
							filter: canSelect ? undefined : 'grayscale(100%)',
						}}
					>
						<ItemImage
							src={`./static/vibes-small/${image.name}.png`}
							alt=""
							width={100}
							height={100}
							onClick={canSelect ? onSelectImage(image) : undefined}
						/>
					</SwiperSlide>
				);
			})}
		</GalleryProvider>
	);
};
export default BackgroundSelector;
