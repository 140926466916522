import Typography from '@mui/material/Typography';

interface HeadingProps {
	text: string;
}

const Heading: React.FC<HeadingProps> = ({ text }) => {
	return (
		<Typography sx={{ marginTop: 2 }} variant="h3" fontFamily={'ArchitectsDaughter'}>
			{text}
		</Typography>
	);
};

export default Heading;
