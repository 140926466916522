import { Paper, styled, Typography } from '@mui/material';
import AdminView from './admin/AdminView';
import { ContractView } from './ContractView';
import { useContract } from '../../providers/ContractProvider';
import Gallery from '../../components/gallery/Gallery';
import LogoImg from '../../img/Logo_WithRays.png';
import Team from './Team';
import { CenterContent, Section } from '../../components/Common';
import DividerImg from '../../img/Divider.png';
import BottomBar from './BottomBar';
import Roadmap from './Roadmap';
import Heading from './Heading';

export const maxContentWidth = 700;

const MintingContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
}));

const ContentContainer = styled(Paper, { label: 'ContentContainer' })(({ theme }) => ({
	borderRadius: 12,
	background: '#6a989f',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	marginTop: theme.spacing(2),
	position: 'relative',
}));

const AdminContainer = styled(Paper, { label: 'AdminContainer' })(({ theme }) => ({
	borderRadius: 12,
	background: `#6a989f`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(2),
}));

const Logo = styled('img')(({ theme }) => ({
	position: 'relative',
	[theme.breakpoints.up('md')]: {
		width: 500,
	},
	[theme.breakpoints.down('md')]: {
		width: 323,
	},
}));

const LogoTextGroup = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'center',
	maxWidth: maxContentWidth,
	color: 'black',
	[theme.breakpoints.down('md')]: {
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

const LogoDescText = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
		textAlign: 'center'
	},
}));

const Divider = styled('img')(({ theme }) => ({
	width: '50%',
	paddingLeft: 10,
	[theme.breakpoints.down('md')]: {
		paddingLeft: 0,
	},
	paddingRight: theme.spacing(2),
	objectFit: 'contain',
}));

const LogoSection = styled(Section, { label: 'Background2' })(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const HomeScreen = () => {
	const { isOwner } = useContract();

	return (
		<MintingContent>
			<LogoSection>
				<Logo src={LogoImg} alt="Logo" />

				<LogoTextGroup>
					<Heading text="Beach Edition" />
					<Divider src={DividerImg} />
					<LogoDescText sx={{ marginTop: 2 }} variant="h6">
						An NFT collection of 10k beautifully drawn badass
					</LogoDescText>
					<LogoDescText variant="h6">women bringing good vibes, inspiration</LogoDescText>
					<LogoDescText variant="h6">and positive change to the world.</LogoDescText>
				</LogoTextGroup>
			</LogoSection>

			<Gallery />
			<CenterContent>
				<ContentContainer>
					<ContractView />
				</ContentContainer>
			</CenterContent>

			{isOwner && (
				<AdminContainer>
					<AdminView />
				</AdminContainer>
			)}
			<Roadmap />
			<Team />
			<BottomBar />
		</MintingContent>
	);
};
