import { styled, Typography } from '@mui/material';
import { Section } from '../../components/Common';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import CallingAdopters from '../../img/Feature_Banner_X_2.png';

import GemIcon from '../../img/Gem.png';
import Heading from './Heading';
import React from 'react';
import { maxContentWidth } from './HomeScreen';

const ContentSection = styled(Section, { label: 'ContentSection' })(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'black',
	width: '100%',
	marginTop: theme.spacing(4),
	marginBottom: theme.spacing(4),
	paddingLeft: theme.spacing(6),
	paddingRight: theme.spacing(6),
}));

const TimelineContainer = styled('div', { label: 'TimelineContainer' })(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
}));

const DescriptionContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	maxWidth: maxContentWidth,
}));

const CompletedMilestoneText = styled(Typography)(({ theme }) => ({
	fontWeight: 900,
	display: 'flex',
	alignItems: 'center',
}));

const Milestone = styled(Typography)(({ theme }) => ({
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(1),
	display: 'flex',
	alignItems: 'center',
}));

const TimelineEntry = styled(TimelineItem)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		'&:before': {
			content: '""',
			flex: 0,
			padding: 0,
			//padding: '6px 16px',
		},
	},
	[theme.breakpoints.up('md')]: {
		'&:before': {
			content: '""',
			flex: 1,
			padding: 0,
			//padding: '6px 16px',
		},
	},
}));
const StoryImage = styled('img')(({ theme }) => ({
	width: '100%',
	maxWidth: maxContentWidth,
	height: '100%',
	objectFit: 'contain',
}));

const GemImg = styled('img')(({ theme }) => ({
	width: 24,
	height: 24,
}));

const BenefitContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
}));

const BenefitText = styled(Typography)(({ theme }) => ({
	background:
		'linear-gradient(to bottom, hsl(173deg 85% 87%) 50%, hsl(176deg 46% 64%))',
	borderRadius: 20,
	// borderStyle: 'solid',
	// borderColor: 'pink',
	// borderWidth: 6,
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	paddingLeft: theme.spacing(8),
	paddingRight: theme.spacing(8),
}));

interface BenefitProps {
	text: string;
}

const Benefit: React.FC<BenefitProps> = ({ text }) => {
	return (
		<BenefitContainer sx={{ marginTop: 2 }}>
			<GemImg sx={{ marginRight: 1 }} src={GemIcon} alt="Gem" />
			<BenefitText
				variant="h6"
				sx={{
					marginLeft: 2,
					display: 'flex',
					justifyContent: 'flex-start',
					alignItems: 'center',
				}}
			>
				{text}
			</BenefitText>
		</BenefitContainer>
	);
};

const RoadmapTimeline = () => {
	return (
		<TimelineContainer sx={{ marginTop: 1 }}>
			<Timeline position="right" sx={{ padding: 0 }}>
				<TimelineEntry>
					<TimelineSeparator>
						<TimelineDot color="primary">
							<img src={GemIcon} alt="Gem" width={24} height={24} />
						</TimelineDot>
						<TimelineConnector sx={{ backgroundColor: 'primary.main' }} />
					</TimelineSeparator>
					<TimelineContent>
						<Typography variant="h5" component="span">
							✨ Minting Phase ✨
						</Typography>
						<CompletedMilestoneText variant="h6">
							✅ Join the discord for early access announcements
						</CompletedMilestoneText>
						<CompletedMilestoneText variant="h6">
							✅ Reveal & Presale
						</CompletedMilestoneText>
						<CompletedMilestoneText variant="h6">
							✅ Mint out remaining collection with free mint
						</CompletedMilestoneText>
					</TimelineContent>
				</TimelineEntry>
				<TimelineEntry>
					<TimelineSeparator>
						<TimelineDot>
							<img src={GemIcon} alt="Gem" width={24} height={24} />
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<Typography variant="h5" component="span">
							💗 Expansion / Benefits 💗
						</Typography>
						<Milestone variant="h6">
							<ArrowRightIcon /> Grow out the community and reach of the
							project. This is the main focus where we will be looking to
							grow organically, as well as running giveaways
						</Milestone>
						<Milestone variant="h6">
							<ArrowRightIcon /> Potential look at new NFTs / ways to expand
							the echo system ecosystem
						</Milestone>
					</TimelineContent>
					<TimelineSeparator />
				</TimelineEntry>
			</Timeline>
		</TimelineContainer>
	);
};

const Roadmap = () => {
	return (
		<React.Fragment>
			<ContentSection>
				<Heading text="Story" />
				<DescriptionContainer sx={{ marginTop: 2, paddingBottom: 2 }}>
					<Typography variant="h6">
						We want to encourage women engaging within the crypto space,
						providing an NFT set that is catered towards a female audience.
						One which realistically depicts cuteness and fashion viewed
						through the artistic lens of a female artist.
					</Typography>
					<Typography variant="h6" sx={{ marginTop: 2 }}>
						We will dedicate 10% of profits towards creating real world change
						by giving back to causes voted on by the community.
					</Typography>
					<Typography variant="h6" sx={{ marginTop: 2 }}>
						Neli's passion for dogs and her experience with fostering rescues
						has also inspired the inclusion of dogs in the collection. Upon
						reaching minting goals a portion of sales will go to supporting
						rescue efforts in the community.
					</Typography>
				</DescriptionContainer>
				<StoryImage src={CallingAdopters} />
			</ContentSection>
			<ContentSection>
				<Heading text="Benefits" />
				<DescriptionContainer sx={{ marginTop: 2 }}>
					<ul>
						<Benefit text="You have complete ownership and IP rights to your NFT" />

						<Benefit text="Gas free resale on OpenSea" />

						<Benefit text="First 200 minters guaranteed to receive a free future collection NFT" />

						<Benefit text="Current holders eligible to receive future random drops" />

						<Benefit text="Multi-wallet minting support" />

						<Benefit text="Optimized minting gas fees" />
					</ul>
				</DescriptionContainer>
			</ContentSection>

			<ContentSection>
				<Heading text={'Roadmap'} />
				<RoadmapTimeline />
			</ContentSection>
		</React.Fragment>
	);
};

export default Roadmap;

