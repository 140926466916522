import { styled, Typography } from '@mui/material';

const Footer = styled('div', { label: 'footer' })(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'white',
	background: 'rgba(0, 0, 0, 0.5)',
	width: '100%',
}));

const BottomBar = () => {
	return (
		<Footer sx={{ padding: 2, marginTop: 3 }}>
			<Typography fontFamily="ArchitectsDaughter" variant="h5">
				#Girlvibes #NFT #Etherium
			</Typography>
		</Footer>
	);
};

export default BottomBar;
