import { styled, Typography } from '@mui/material';
import { Section } from '../../components/Common';

import Jason from '../../img/Profile_Jason_Speech.png';
import Neli from '../../img/Profile_Neli_Speech.png';
import Phil from '../../img/Profile_Phil_Speech.png';
import Wendy from '../../img/Profile_Wendy.png';
import TwitterLogo from '../../img/Twitter_Black.svg';
import { IconButton } from '@mui/material';
import { goToTwitterHandle } from '../../util';

const LogoTextGroup = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'black',
	width: '100%',
}));

const TeamPersonAvatar = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	position: 'relative',
}));

const TeamAvatarContainer = styled('div')(({ theme }) => ({
	width: '100%',
	justifyContent: 'center',
	color: 'black',

	display: 'grid',
	gridGap: theme.spacing(3),
	gridTemplateColumns: 'repeat(auto-fit, minmax(256px, auto))',
}));

const ProfileImgGroup = styled('div')(({ theme }) => ({
	width: 256,
	height: 256,
}));

const ProfileImg = styled('img')(({ theme }) => ({
	width: '100%',
	height: '100%',
}));

const Logo = styled('img')(({ theme }) => ({
	width: 32,
	height: 32,
}));

interface TeamPersonProps {
	src: string;
	name: string;
	role: string;
	speech?: string;
	speechPosition?: 'top' | 'bottom';
	description: string;
	twitterUrl?: string;
}

const TeamPerson: React.FC<TeamPersonProps> = ({
	src,
	name,
	role,
	description,
	twitterUrl,
}) => {
	return (
		<TeamPersonAvatar>
			<ProfileImgGroup>
				<ProfileImg src={src} alt="Profile" />
			</ProfileImgGroup>

			<Typography
				variant="h5"
				fontFamily="ArchitectsDaughter"
				sx={{ minHeight: 70, maxWidth: 300, textAlign: 'center' }}
			>
				{`${name} - ${role}`}
				{twitterUrl && (
					<IconButton
						sx={{ marginLeft: 1 }}
						size="small"
						color="secondary"
						onClick={() => goToTwitterHandle(twitterUrl)}
					>
						<Logo src={TwitterLogo} alt="Twitter" />
					</IconButton>
				)}
			</Typography>
			<Typography
				sx={{ minWidth: 200, textAlign: 'center', padding: 2, maxWidth: 200 }}
			>
				{description}
			</Typography>
		</TeamPersonAvatar>
	);
};

const Team = () => {
	return (
		<Section sx={{ marginTop: 2, marginBottom: 2 }}>
			<LogoTextGroup>
				<Typography variant="h2" fontFamily={'ArchitectsDaughter'}>
					The Team
				</Typography>
				<TeamAvatarContainer>
					<TeamPerson
						src={Neli}
						name="Neli"
						role="Artist / Vision"
						description={
							'16 Years of experience in creating 3D and 2D art for games'
						}
						twitterUrl="@NeliChaneva"
					/>
					<TeamPerson
						src={Jason}
						name="Jason"
						role="Marketing"
						description={
							'Digital marketing specialist with 6 years of experience working in the mobile space'
						}
						twitterUrl="@JayYuNZ"
					/>

					<TeamPerson
						src={Phil}
						name="Phil"
						role="Developer"
						description={
							'7 years experience in game / software development. Smart contract, web3 and web developer'
						}
						twitterUrl="@PRoigard"
					/>
					<TeamPerson
						src={Wendy}
						name="Wendy"
						role="Community Manager"
						description={
							'Animal lover, dog Mom. Crafting, sewing and DIY enjoyer'
						}
					/>
				</TeamAvatarContainer>
			</LogoTextGroup>
		</Section>
	);
};

export default Team;
