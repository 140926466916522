import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useContract } from '../../../providers/ContractProvider';
import { ActionButton, Container, InfoText, RootView } from '../../../components/Common';
import { CircularProgress, styled } from '@mui/material';
import Whitelist from './Whitelist';
import Giftlist from './Giftlist';

export const Field = styled(TextField)(({ theme }) => ({
	background: '#8bafb4',
}));
const FormRow = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

const FormGrid = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '2fr 1fr',
	columnGap: theme.spacing(2),
	rowGap: theme.spacing(2),
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

interface AdminSettingProps {
	existingValue: string;
	valueSetMethod: (val: any) => Promise<void>;
	label: string;
	placeholder?: string;
	helperLabel?: string;
	buttonText?: string;
}

const AdminSetting: React.FC<AdminSettingProps> = ({
	existingValue,
	valueSetMethod,
	placeholder,
	helperLabel,
	label,
	buttonText = 'Save',
}) => {
	const [val, setVal] = useState<string>(existingValue);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		setVal(existingValue);
	}, [existingValue]);

	const onChangeVal = (e: any) => {
		setVal(e.target.value);
	};
	const onClickSaveValue = () => {
		setSaving(true);
		valueSetMethod(val)
			.then(() => {
				setSaving(false);
			})
			.catch(() => {
				setSaving(false);
			});
	};
	return (
		<React.Fragment>
			<Field
				value={val}
				label={label}
				placeholder={placeholder}
				variant={'outlined'}
				helperText={helperLabel}
				onChange={onChangeVal}
			/>
			<ActionButton
				variant="contained"
				onClick={onClickSaveValue}
				disabled={saving}
			>
				{saving && <CircularProgress color="inherit" />}
				{buttonText}
			</ActionButton>
		</React.Fragment>
	);
};

interface AdminToggleProps {
	existingValue: boolean;
	toggleMethod: () => Promise<void>;
	canToggleOff?: boolean;
	label: string;
}

const AdminToggle: React.FC<AdminToggleProps> = ({
	existingValue,
	canToggleOff = true,
	label,
	toggleMethod,
}) => {
	const [saving, setSaving] = useState(false);

	const onToggle = () => {
		if (!canToggleOff && existingValue === true) {
			return;
		}
		setSaving(true);
		toggleMethod()
			.then(() => {
				setSaving(false);
			})
			.catch(() => {
				setSaving(false);
			});
	};
	return (
		<FormControlLabel
			label={label}
			control={
				<React.Fragment>
					{saving && <CircularProgress color="inherit" />}
					<Switch
						checked={existingValue}
						onChange={onToggle}
						disabled={saving}
					/>
				</React.Fragment>
			}
		/>
	);
};

const AdminView: React.FC = () => {
	const {
		toggleMinting,
		toggleAllowList,
		isOwner,
		contractEth,
		withdraw,
		notRevealedURI,
		baseURI,
		setBaseURI,
		setNotRevealedURI,
		setMaxSupply,
		setMaxGiftSupply,
		setMaxMintAmount,
		setPrice,
		mintPrice,
		contractValues,
		maxSupply,
		teamMint,
		reveal,
	} = useContract();

	const [teamMintAmount] = useState(1);

	const onClickWithdraw = () => {
		withdraw();
	};

	return (
		<RootView>
			{isOwner && (
				<Container>
					<Typography variant="h5">Admin</Typography>
					<InfoText variant="h6">{`ETH in Contract: ${contractEth}`}</InfoText>

					<AdminToggle
						label="Toggle Minting active"
						existingValue={contractValues.mintingActive}
						toggleMethod={toggleMinting}
					/>
					<AdminToggle
						label="Toggle Whitelist only"
						existingValue={contractValues.allowListOnly}
						toggleMethod={toggleAllowList}
					/>
					<AdminToggle
						label="NFT Revealed"
						existingValue={contractValues.revealed}
						toggleMethod={reveal}
						canToggleOff={false}
					/>
					<FormGrid>
						<AdminSetting
							label="Not Revealed URL"
							placeholder="ipfs://"
							helperLabel="Should start with ipfs:// and be a full path to a .json 
                            file containing image url and teaser data"
							existingValue={notRevealedURI}
							valueSetMethod={setNotRevealedURI}
						/>

						<AdminSetting
							label="Base URL"
							placeholder="ipfs://"
							helperLabel="Should start with ipfs:// and be a path to a folder
                            containing the real NFT image metadata files for each"
							existingValue={baseURI}
							valueSetMethod={setBaseURI}
						/>

						<AdminSetting
							label="Max Supply"
							existingValue={maxSupply.toString()}
							valueSetMethod={setMaxSupply}
						/>
						<AdminSetting
							label="Max Gift Supply"
							existingValue={contractValues.giftSupply.toString()}
							valueSetMethod={setMaxGiftSupply}
						/>
						<AdminSetting
							label="Max Mint Per Transaction"
							existingValue={contractValues.maxMintAmount.toString()}
							valueSetMethod={setMaxMintAmount}
						/>
						<AdminSetting
							label="Team Mint"
							existingValue={teamMintAmount.toString()}
							valueSetMethod={teamMint}
							buttonText="Mint"
						/>
						<AdminSetting
							label="Set Price (ETH)"
							existingValue={mintPrice.toString()}
							valueSetMethod={setPrice}
						/>
					</FormGrid>

					<Whitelist />
					<Giftlist />

					<FormRow>
						<ActionButton
							variant="contained"
							onClick={onClickWithdraw}
							disabled={contractEth <= 0}
						>
							Withdraw Eth
						</ActionButton>
					</FormRow>
				</Container>
			)}
		</RootView>
	);
};

export default AdminView;

