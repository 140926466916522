import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { ActionButton } from '../../../components/Common';
import { useContract } from '../../../providers/ContractProvider';
import BulkAddressAction from './BulkAddressAction';

const Whitelist: React.FC = () => {
	const { setAllowList } = useContract();
	const [saving, setSaving] = useState(false);

	const onClickAdd = (addressList: string[]) => () => {
		setSaving(true);
		setAllowList(addressList, true)
			.then(() => {
				setSaving(false);
			})
			.catch(() => {
				setSaving(false);
			});
	};
	const onClickRemove = (addressList: string[]) => () => {
		setSaving(true);
		setAllowList(addressList, false)
			.then(() => {
				setSaving(false);
			})
			.catch(() => {
				setSaving(false);
			});
	};

	return (
		<BulkAddressAction
			title="Whitelist Action List"
			renderActions={(addressList) => {
				return (
					<div>
						<ActionButton
							variant="contained"
							color="error"
							onClick={onClickRemove(addressList)}
							disabled={saving}
						>
							{saving && <CircularProgress color="inherit" />}
							Remove
						</ActionButton>
						<ActionButton
							sx={{ marginTop: 1 }}
							variant="contained"
							onClick={onClickAdd(addressList)}
							disabled={saving}
						>
							{saving && <CircularProgress color="inherit" />}
							Add
						</ActionButton>
					</div>
				);
			}}
		/>
	);
};

export default Whitelist;
