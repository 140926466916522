import { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper as SwiperReact } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

import './gallery.css';
import { styled, SxProps, Theme, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { AutoplayOptions } from 'swiper/types/modules/autoplay';

const SwiperComponent = styled('div')(({ theme }) => ({
	display: 'flex',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));
const SwiperGroup = styled('div', { label: 'Gallery' })(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const ItemImage = styled('img')(({ theme }) => ({
	userSelect: 'none',
	maxHeight: '100%',
	maxWidth: '100%',
	cursor: 'pointer',
}));

interface GalleryProviderProps {
	numberOfSlides: number;
	maxWidth?: number;
	autoPlay?: boolean | AutoplayOptions;
	centeredSlides?: boolean;
	sx?: SxProps<Theme>;
	paginationClassName?: string;
	spacing?: number;
	slidesPerView?: number | 'auto';
	pagination?: boolean;

	width?: number;
	height?: number;
    initialSlide?: number;
}

export const GalleryProvider: React.FC<GalleryProviderProps> = ({
	numberOfSlides,
	children,
	maxWidth,
	autoPlay,
	centeredSlides,
	sx,
	paginationClassName,
	width,
	height,
	slidesPerView,
	spacing,
	pagination,
    initialSlide = 0
}) => {
	const theme = useTheme();

	const hasMoreThanOne = useMemo(() => {
		return numberOfSlides > 1;
	}, [numberOfSlides]);

	if (numberOfSlides === 0) return null;

	return (
		<SwiperGroup sx={sx}>
			<SwiperComponent sx={{ maxWidth }}>
				<SwiperReact
					modules={[Pagination, Navigation, Autoplay]}
					spaceBetween={spacing ? parseInt(theme.spacing(spacing)) : 0}
					pagination={
						pagination
							? { clickable: true, el: `.${paginationClassName}` }
							: false
					}
					navigation={hasMoreThanOne}
					loop
					autoplay={autoPlay}
					slidesPerView={slidesPerView}
					loopedSlides={numberOfSlides}
					centeredSlides={centeredSlides}
					slideToClickedSlide={true}
					grabCursor={hasMoreThanOne}
					width={width}
					height={height}
                    initialSlide={initialSlide}
                    
				>
					{children}
				</SwiperReact>
			</SwiperComponent>
			{hasMoreThanOne && pagination && (
				<div
					className={paginationClassName}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: theme.spacing(2),
					}}
				/>
			)}
		</SwiperGroup>
	);
};
