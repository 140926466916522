export const BackgroundElements = [
	{ path: 'Beach Retreat', unlocked: false },
	{ path: 'Blue', unlocked: false },
	{ path: 'Cali', unlocked: false },
	{ path: 'Clear Sky', unlocked: false },
	{ path: 'Coconut Island', unlocked: false },
	{ path: 'Dawn', unlocked: false },
	{ path: 'Evening Breeze', unlocked: false },
	{ path: 'Evening Glow', unlocked: true },
	{ path: 'Evening Ocean', unlocked: false },
	{ path: 'Gentle Hug', unlocked: true },
	{ path: 'Gentle Wave', unlocked: false },
	{ path: 'Hawaii', unlocked: false },
	{ path: 'Hot Pink', unlocked: true },
	{ path: 'Icecream', unlocked: true },
	{ path: 'Lolli', unlocked: true },
	{ path: 'Mint', unlocked: true },
	{ path: 'No Filter', unlocked: false },
	{ path: 'Peach', unlocked: true },
	{ path: 'Pretty In Pink', unlocked: true },
	{ path: 'Purple Glow', unlocked: true },
	{ path: 'Rainbow', unlocked: true },
	{ path: 'Remote Vaccation', unlocked: false },
	{ path: 'Sunset Tones', unlocked: true },
	{ path: 'Sunset', unlocked: false },
	{ path: 'Surf Blue', unlocked: false },
	{ path: 'Surf Green', unlocked: false },
	{ path: 'Surf White', unlocked: false },
	{ path: 'Tricolor', unlocked: true },
	{ path: 'Trippy', unlocked: true },
	{ path: 'Tropical', unlocked: false },
	{ path: 'Umbrellas', unlocked: false },
	{ path: 'Warm Breeze', unlocked: false },
	{ path: 'Warm Green', unlocked: true },
	{ path: 'Waves', unlocked: false },
];
