import { styled } from '@mui/material';
import SocialLinks from './SocialLinks';
import NavMenu from './NavMenu';
import WalletConnect from './WalletConnect';

interface HeaderProps {
	show: boolean;
}

const HeaderContainer = styled('div')<HeaderProps>(
	({ theme, show }) => ({
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		position: 'fixed',
		top: 0,
		zIndex: 999,
		flexFlow: 'row wrap',

		padding: theme.spacing(2),
		//background: 'rgba(18, 18, 18, 0.7)',
		//backdropFilter: 'blur(20px)',
		//transform: 'translateY(0px)',
		// background: `linear-gradient(to top right, hsl(176deg 46% 64%) 50%, hsl(173deg 85% 87%) 100%)`,
		background: theme.palette.secondary.dark,

		boxShadow: show ? `0 2px 60px 0 ${theme.palette.primary.dark}` : 'none',

		transition:
			'transform .3s,box-shadow .3s,-webkit-transform .3s,-webkit-box-shadow .3s',
	}),
	(props) => {
		if (!props.show) {
			return {
				transform: 'translateY(-100%)',
			};
		}
	}
);

const RightContent = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
}));

const Header = () => {
	// const [lastScrollY, setLastScrollY] = useState(0);
	// const [show, setShow] = useState(true);
    const show = true;

	// const controlNavbar = useCallback(() => {
	// 	if (typeof window !== 'undefined') {
	// 		if (window.scrollY > lastScrollY) {
	// 			// if scroll down hide the navbar
	// 			setShow(false);
	// 		} else {
	// 			// if scroll up show the navbar
	// 			setShow(true);
	// 		}

	// 		// remember current page location to use in the next move
	// 		setLastScrollY(window.scrollY);
	// 	}
	// }, [lastScrollY]);

	// useEffect(() => {
	// 	if (typeof window !== 'undefined') {
	// 		window.addEventListener('scroll', controlNavbar);

	// 		// cleanup function
	// 		return () => {
	// 			window.removeEventListener('scroll', controlNavbar);
	// 		};
	// 	}
	// }, [controlNavbar, lastScrollY]);

	return (
		<HeaderContainer show={show}>
			<NavMenu />

			<RightContent>
				<SocialLinks />
				<WalletConnect />
			</RightContent>
		</HeaderContainer>
	);
};

export default Header;

