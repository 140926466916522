import { styled, Typography } from '@mui/material';
import { goToLink } from '../../util';
import { ActionButton } from '../Common';

const Container = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

export const NoMetaMaskExtension: React.FC = () => {
	const onActionClicked = () => {
        goToLink('https://metamask.io/download/')
    };
	return (
		<Container>
			<Typography sx={{marginRight: 2}}>You must have Metamask browser extension to connect</Typography>
			<ActionButton
				sx={{ maxWidth: 100 }}
				variant="contained"
				onClick={onActionClicked}
			>
				{'Install'}
			</ActionButton>
		</Container>
	);
};
