import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { useContract } from '../../providers/ContractProvider';
import { GalleryProvider, ItemImage } from './GalleryProvider';

export const UserOwnedGallery = () => {
	const { userNFTList } = useContract();

	return (
		<GalleryProvider
			numberOfSlides={userNFTList.length}
			maxWidth={604}
			sx={{ paddingLeft: 4, paddingRight: 4, marginTop: 2 }}
			paginationClassName="user-owned-nft-pagination"
			centeredSlides={true}
			slidesPerView="auto"
		>
			{userNFTList.map((nft) => {
				return (
					<SwiperSlide key={nft.tokenId}>
						<ItemImage src={nft.metadata.image} alt="" />
					</SwiperSlide>
				);
			})}
		</GalleryProvider>
	);
};
