import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const RPC_URL_1 = `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`;
const RPC_URL_4 = `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`;

export enum ConnectType {
	NotConnected,
	Injected,
	WalletConnect,
	Infura,
}

// For metamask and other inejected.
export const injected = new InjectedConnector({
	supportedChainIds: [1, 4, 1337],
});

// Wallet connect will only support mainnet
export const walletconnect = new WalletConnectConnector({
	rpc: { 1: RPC_URL_1 },
	qrcode: true,
});

// For use when no wallet is connected but we still want to retrieve data.
export const infura = new NetworkConnector({
	urls: {
		1: RPC_URL_1,
		4: RPC_URL_4,
	},
	// Connect to the chain specified by the envrionment.
	defaultChainId: process.env.REACT_APP_CHAIN_ID
		? parseInt(process.env.REACT_APP_CHAIN_ID)
		: 4,
});

export const Connectors = {
	[ConnectType.Infura]: infura,
	[ConnectType.Injected]: injected,
	[ConnectType.WalletConnect]: walletconnect,
};
