import { IconButton, styled, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRef, useState } from 'react';
import { ActionButton } from '../../../components/Common';
import RemoveIcon from '@mui/icons-material/Remove';

const AddressListContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
	width: '100%',
}));

const FormGrid = styled('div')(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '2fr 1fr',
	columnGap: theme.spacing(2),
	rowGap: theme.spacing(2),
	alignItems: 'center',
	justifyContent: 'center',
	marginTop: theme.spacing(1),
	marginBottom: theme.spacing(1),
}));

const Address = styled('div')(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(1),
	justifyContent: 'space-between',
	borderBottom: '1px solid black',
}));
const AddressText = styled(Typography)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

interface BulkAddressActionProps {
	title: string;
	renderActions?: (addressList: string[]) => React.ReactElement;
}

const BulkAddressAction: React.FC<BulkAddressActionProps> = ({
	renderActions,
	title,
}) => {
	const [addressList, setAddressList] = useState<string[]>([]);
	const [newAddressText, setNewAddressText] = useState('');

	const inputFile = useRef<HTMLInputElement>(null);

	const onTextChange = (e: any) => {
		const text = e.target.value;
		setNewAddressText(text);
	};

	const onClickAddAddress = () => {
		// const exists = addressList.find((a) => a === newAddressText);
		// if (exists) {
		// 	return;
		// }
		// Add to the list.
		setAddressList((prev) => [...prev, newAddressText]);
		setNewAddressText('');
	};

	const removeAddress = (address: string) => () => {
		setAddressList((prev) => prev.filter((a) => a !== address));
	};

	const onChangeFile = (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		const file = e.target.files[0];

		const reader = new FileReader();
		reader.onload = (event) => {
			const file: any = event.target?.result;
			if (!file) return;

			const allLines = file.split(/\r\n|\n/);
			// Reading line by line
			setAddressList((prev) => [...prev, ...allLines]);
		};

		reader.readAsText(file);
	};

	const onButtonClick = () => {
		if (!inputFile) return;

		inputFile.current?.click();
	};

	return (
		<AddressListContainer>
			<Typography fontFamily="ArchitectsDaughter" variant="h5">
				{title}
			</Typography>
			<input
				type="file"
				id="file"
				ref={inputFile}
				style={{ display: 'none' }}
				onChange={onChangeFile}
			/>
			<FormGrid sx={{ width: '100%' }}>
				<div>
					{addressList.map((a) => {
						return (
							<Address key={a}>
								<AddressText>{a}</AddressText>
								<IconButton onClick={removeAddress(a)}>
									<RemoveIcon />
								</IconButton>
							</Address>
						);
					})}
					<FormGrid sx={{ width: '100%' }}>
						<TextField
							variant="outlined"
							value={newAddressText}
							onChange={onTextChange}
						/>
						<ActionButton variant="contained" onClick={onClickAddAddress}>
							Add
						</ActionButton>
						<ActionButton variant="contained" onClick={onButtonClick}>
							Import file
						</ActionButton>
					</FormGrid>
				</div>
				{addressList.length > 0 && renderActions && renderActions(addressList)}
			</FormGrid>
		</AddressListContainer>
	);
};
export default BulkAddressAction;
