import { InputAdornment, styled, TextField, Typography } from '@mui/material';
import { createRef, useCallback, useEffect, useState } from 'react';
import { ActionButton, CenterContent } from '../../components/Common';
import { LightThemeProvider } from '../../theme';

import EditIcon from '@mui/icons-material/Edit';
import { UserOwnedGallery } from '../../components/gallery/UserGallery';
import { loadImage } from '../../util';
import BackgroundSelector, {
	ImageDetail,
} from '../../components/gallery/BackgroundSelector';
import Picker from 'emoji-picker-react';
import Heading from '../home/Heading';
import { isMobile } from 'react-device-detect';

interface VibesScreenProps {}

const VibesHome = styled('div', { label: 'VibesHome' })(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	height: '100%',
}));

export const Field = styled(TextField)(({ theme }) => ({
	//background: '#8bafb4',
	width: '100%',
}));

const FieldContainer = styled('div')(({ theme }) => ({
	background: '#FFF',
	color: 'black',
	width: '100%',
	flexGrow: 1,
	padding: theme.spacing(1),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

const InfoContainer = styled('div')(({ theme }) => ({
	background: '#FFF',
	color: 'black',
	width: '100%',
	flexGrow: 1,
	padding: theme.spacing(1),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	justifyContent: 'center',
}));

const ButtonContainer = styled('div')(({ theme }) => ({
	background: '#8bafb4',
	width: '100%',
	padding: theme.spacing(2),
}));

const width = 1024;
const height = 1024;
const fontSize = 72;
const maxCharsPerLine = 17;
const maxLines = 3;
const maxChars = maxCharsPerLine * maxLines + 1;

const splitString = (srcString: string, lines: number, numberOfChars: number) => {
	let final = '';
	let target = Array.from(srcString);

	for (let i = 0; i < target.length; i += maxCharsPerLine) {
		const chunk = target.slice(i, i + maxCharsPerLine);
		final += chunk.join('') + '\n';
	}
	return final;
};

const VibesScreen: React.FC<VibesScreenProps> = () => {
	const canvas = createRef<HTMLCanvasElement>();
	const [background, setBackground] = useState<string>();
	const [text, setText] = useState('');

	const drawText = useCallback(
		(ctx: CanvasRenderingContext2D) => {
			// Draw the text.
			const x = width / 2;
			const y = 400;

			ctx.fillStyle = '#000';
			ctx.font = `${fontSize}px ArchitectsDaughter`;

			//const textWidth = ctx.measureText(firstLine).width;

			//ctx.fillText(text, x - textWidth / 2, y);
			const lineHeight = 20;

			ctx.save();
			ctx.translate(x, y);
			ctx.rotate(-Math.PI / 45);
			ctx.textAlign = 'center';

			var split = splitString(text, maxLines, maxCharsPerLine);
			var lines = split.split('\n');

			const offsetY = lineHeight / 2;
			for (var i = 0; i < lines.length; i++)
				ctx.fillText(lines[i], 0, offsetY + i * (fontSize + lineHeight));

			ctx.restore();
		},
		[text]
	);

	const buildImage = useCallback(() => {
		if (!canvas) return;
		if (!canvas.current) return;

		const context = canvas.current.getContext('2d');
		if (!context) return;

		let loadedElements = [];
		if (background) {
			loadedElements.push(loadImage(background));
		}
		loadedElements.push(loadImage('static/Vibe_01.png'));

		Promise.all(loadedElements)
			.then((renderObjectArray) => {
				// Load all the background components.
				renderObjectArray.forEach((renderObject) => {
					context.drawImage(renderObject, 0, 0, width, height);
				});

				// Draw the text.
				drawText(context);
			})
			.catch((reason) => {
				console.log(reason);
			});
	}, [background, canvas, drawText]);

	useEffect(() => {
		buildImage();
	}, [buildImage]);

	const updateText = (txt: string) => {
		const len = txt.length;

		if (len > maxChars) return;

		setText(txt);
	};

	const onChangeFirstLine = (e: any) => {
		const txt: string = e.target.value;
		updateText(txt);
	};

	const onEmojiClick = (e: any, chosenEmoji: any) => {
		console.log(chosenEmoji.emoji);
		updateText(text + chosenEmoji.emoji);
	};

	const onSelectBackground = (detail: ImageDetail) => {
		setBackground(`static/vibes-large/${detail.name}.png`);
	};

	const onClickSave = () => {
		if (!canvas.current) return;

		const c = canvas.current;
		if (!c) return;

		const image = c.toDataURL('image/png').replace('image/png', 'image/octet-stream');

		var link = document.createElement('a');
		link.download = 'GoodVibes.png';
		link.href = image;
		link.click();
	};

	return (
		<VibesHome>
			<UserOwnedGallery />
			<CenterContent>
				<LightThemeProvider>
					<FieldContainer>
						<InfoContainer sx={{ marginBottom: 2 }}>
							<Heading text="Good Vibes Generator" />
							<Typography variant="h6" sx={{ marginTop: 2 }}>
								You can use this tool to generate some good vibes for
								yourself or to share to the rest of the world!
							</Typography>
							<Typography variant="h6" sx={{ marginTop: 2 }}>
								If you own a GirlVibe with a rare / unique background
								connect your wallet to have access to those backgrounds.
							</Typography>
						</InfoContainer>
						<Field
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EditIcon />
									</InputAdornment>
								),
							}}
							value={text}
							variant="outlined"
							placeholder="Enter message"
							onChange={onChangeFirstLine}
							helperText={`${text.length} / ${maxChars} characters remaining`}
							multiline
							inputProps={{ spellCheck: 'false' }}
						/>

						{!isMobile && (
							<Picker
								onEmojiClick={onEmojiClick}
								pickerStyle={{ width: '100%', marginTop: '8px' }}
							/>
						)}
					</FieldContainer>
				</LightThemeProvider>
				<canvas
					ref={canvas}
					height={height}
					width={width}
					style={{ width: '100%', height: '100%' }}
				/>
				<BackgroundSelector onSelect={onSelectBackground} />
				<ButtonContainer>
					<ActionButton
						sx={{ marginTop: 1 }}
						variant="contained"
						onClick={onClickSave}
					>
						Save
					</ActionButton>
				</ButtonContainer>
			</CenterContent>
		</VibesHome>
	);
};

export default VibesScreen;

