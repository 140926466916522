import Item1 from '../../img/promo/349.png';
import Item2 from '../../img/promo/352.png';
import Item3 from '../../img/promo/356.png';
import Item4 from '../../img/promo/358.png';
import Item5 from '../../img/promo/369.png';
import Item6 from '../../img/promo/387.png';
import Item7 from '../../img/promo/390.png';
import Item8 from '../../img/promo/486.png';
import Item9 from '../../img/promo/504.png';
import Item10 from '../../img/promo/533.png';
import Item11 from '../../img/promo/536.png';
import Item12 from '../../img/promo/553.png';
import Item13 from '../../img/promo/668.png';
import Item14 from '../../img/promo/686.png';
import Item15 from '../../img/promo/721.png';
import Item16 from '../../img/promo/745.png';
import Item17 from '../../img/promo/MadeUp_02.png';
import Item18 from '../../img/promo/MadeUp.png';

import { SwiperSlide } from 'swiper/react/swiper-react.js';
import { GalleryProvider, ItemImage } from './GalleryProvider';
import { useMemo } from 'react';
import { useWindowResize } from '../../util';

const Gallery = () => {

    const dim = useWindowResize();

	const slidesPerView = useMemo(() => {
		return Math.min(Math.floor(dim.width / 300), 18);
	}, [dim.width]);

	return (
		<GalleryProvider
			numberOfSlides={18}
			slidesPerView={slidesPerView}
			autoPlay={{
				delay: 1500,
				pauseOnMouseEnter: true,
				disableOnInteraction: false,
			}}
			paginationClassName="my-custom-pagination-div"
            sx={{ marginTop: 5 }}
            centeredSlides={true}
            spacing={2}
            pagination={true}
            
		>
			<SwiperSlide>
				<ItemImage src={Item1} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item2} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item3} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item4} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item5} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item6} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item7} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item8} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item9} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item10} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item11} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item12} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item13} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item14} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item15} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item16} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item17} alt="" />
			</SwiperSlide>
			<SwiperSlide>
				<ItemImage src={Item18} alt="" />
			</SwiperSlide>
		</GalleryProvider>
	);
};

export default Gallery;
