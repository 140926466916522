import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Contract } from 'web3-eth-contract';
import { CONTRACT_ABI } from '../data/abi';
import { useWallet } from './WalletProvider';

interface ContractValues {
	maxSupply: number;
	price: number;
	mintingActive: boolean;
	maxMintAmount: number;
	revealed: boolean;
	notRevealedURI: string;
	baseExtension: string;
	allowListOnly: boolean;
	giftSupply: number;
	giftTotal: number;
}

interface Trait {
    trait_type: string;
    value: string;
}

export interface NFT {
	tokenId: number;
	metadata: Metadata;
}

export interface Metadata {
	image: string;
    traits: Trait[];
	//description: string;
	//edition: number;
	//date: number;
	//attributes: { trait_type: string; value: string }[];
}

type Method = keyof ContractValues;

const MethodList: Method[] = [
	'maxSupply',
	'price',
	'mintingActive',
	'maxMintAmount',
	'revealed',
	'notRevealedURI',
	'baseExtension',
	'allowListOnly',
	'giftSupply',
	'giftTotal',
];

interface ContractContext {
	contractValues: ContractValues;
	tokenURI: (tokenId: number) => Promise<string>;
	mint: (quantity: number) => Promise<void>;

	totalMinted: number;
	mintPrice: number;
	maxSupply: number;
	/**
	 * The ipfs:// path used when the nfts have been revealed
	 */
	baseURI: string;
	/**
	 * The ipfs:// path when the nfts have not been revealed.
	 */
	notRevealedURI: string;

	// Admin functions.

	setPrice: (price: number) => Promise<void>;
	setBaseURI: (uri: string) => Promise<void>;
	setNotRevealedURI: (uri: string) => Promise<void>;
	setBaseURIExtension: (ext: string) => Promise<void>;
	toggleMinting: () => Promise<void>;
	toggleAllowList: () => Promise<void>;
	reveal: () => Promise<void>;
	setMaxMintAmount: (amount: number) => Promise<void>;
	setMaxGiftSupply: (amount: number) => Promise<void>;
	setMaxSupply: (amount: number) => Promise<void>;
	teamMint: (amount: number) => Promise<void>;
	withdraw: () => Promise<void>;
	setAllowList: (addressList: string[], enabled: boolean) => Promise<void>;
	gift: (addressList: string[]) => Promise<void>;

	requestInProgress: boolean;
	isOwner: boolean;
	isAllowListed: boolean;
	userOwnedTokens: number;
	userNFTList: NFT[];
	contractEth: number;
}

const defaultContractValues: ContractValues = {
	maxSupply: 0,
	price: 0,
	mintingActive: false,
	maxMintAmount: 0,
	revealed: false,
	notRevealedURI: '',
	baseExtension: '',
	allowListOnly: false,
	giftSupply: 0,
	giftTotal: 0,
};

const contractContext = React.createContext<ContractContext>({
	contractValues: defaultContractValues,
	totalMinted: 0,
	mintPrice: 0,
	maxSupply: 0,
	isOwner: false,
	isAllowListed: false,
	userOwnedTokens: 0,
	userNFTList: [],
	contractEth: 0,
	notRevealedURI: '',
	baseURI: '',
	requestInProgress: false,
	tokenURI: async () => {
		return '';
	},
	mint: () => Promise.reject(),
	setPrice: () => Promise.reject(),
	setBaseURI: () => Promise.reject(),
	setNotRevealedURI: () => Promise.reject(),
	setBaseURIExtension: () => Promise.reject(),
	toggleMinting: () => Promise.reject(),
	toggleAllowList: () => Promise.reject(),
	reveal: () => Promise.reject(),
	setMaxMintAmount: () => Promise.reject(),
	setMaxGiftSupply: () => Promise.reject(),
	setMaxSupply: () => Promise.reject(),
	teamMint: () => Promise.reject(),
	withdraw: () => Promise.reject(),
	setAllowList: () => Promise.reject(),
	gift: () => Promise.reject(),
});

/**
 * Provides access to the GirlVibes smart contract. Must be nested under the WalletProvider in order
 * to interact with the smart contract such as minting, admin functions. Some read-only state is accesible by
 * Infura connection so that information can be displayed about the smart contract before the user connects their wallet.
 */
const ContractProvider: React.FC = ({ children }) => {
	const [totalSupply, setTotalSupply] = useState(0);
	const [maxSupply, setMaxSupplyValue] = useState(0);
	const [ethPrice, setEthPrice] = useState(0);
	const [isAllowListed, setIsAllowListed] = useState(false);
	const [contract, setContract] = useState<Contract | undefined>();
	const [owner, setOwner] = useState<string | undefined>();
	const [contractEth, setContractEth] = useState(0);
	const [baseURI, setBaseURIValue] = useState('');
	const [notRevealedURI, setNotRevealedURIValue] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [userOwnedTokens, setUserOwnedTokens] = useState(0);
	const [userNFTList, setUserNFTList] = useState<NFT[]>([]);

	const [contractValues, setContractValues] =
		useState<ContractValues>(defaultContractValues);

	// Wallet provider hook
	const { web3, walletAddress } = useWallet();

	/**
	 * Calls a method by key and sets the value. Useful for getting bulk state from smart contract.
	 */
	const callContractMethod = useCallback(
		(key: Method) => {
			return contract?.methods[key]()
				.call()
				.then((result: any) => {
					setContractValues((prev) => ({
						...prev,
						[key]: result,
					}));
				});
		},
		[contract]
	);

	// Load up all the contract values on wallet change
	useEffect(() => {
		// Todo wrap in a promise and gather all the results.
		MethodList.forEach((k) => {
			callContractMethod(k);
		});
	}, [contract, callContractMethod, walletAddress]);

	useEffect(() => {
		if (!web3) {
			return;
		}
		// Todo: Possibly get the current gas fees and multiply it by our known gas usage for minting
		// that way we can give the user a cheaper deal as Metamask and others default to much higher gas limit

		// web3.eth.getGasPrice().then((price) => {
		// 	console.log('Gas Price', price);
		// });
		// web3.eth.getBlock('latest').then((block) => {
		// 	console.log('Latest gas limit', block.gasLimit);
		// 	console.log('Latest gas used', block.gasUsed);
		// 	console.log('Base fee', block.baseFeePerGas);
		// });
	}, [web3]);

	useEffect(() => {
		if (!web3) return;

		// Initialise our contract once web3 is valid.
		const c = new web3.eth.Contract(
			CONTRACT_ABI,
			process.env.REACT_APP_CONTRACT_ADDRESS
		);
		setContract(c);
	}, [web3]);

	/**
	 * Determine if the connected wallet is the smart contract owner
	 */
	const isOwner = useMemo(() => {
		if (!walletAddress) return false;
		if (!owner) return false;

		if (walletAddress.toLowerCase() === owner.toLowerCase()) return true;

		return false;
	}, [walletAddress, owner]);

	const refreshOwner = useCallback<() => Promise<void>>(() => {
		return contract?.methods
			.owner()
			.call()
			.then((owner: string) => {
				setOwner(owner);
			});
	}, [contract]);

	const refreshIsWhitelisted = useCallback<() => Promise<void>>(() => {
		if (!walletAddress) return Promise.reject();
		return contract?.methods
			.isAllowListed(walletAddress)
			.call()
			.then((result: boolean) => {
				setIsAllowListed(result);
			});
	}, [walletAddress, contract]);

	const refreshMyBalance = useCallback<() => Promise<number>>(() => {
		if (!walletAddress) return Promise.reject();
		return contract?.methods
			.balanceOf(walletAddress)
			.call()
			.then((result: number) => {
				setUserOwnedTokens(result);
				return result;
			});
	}, [walletAddress, contract]);

	const refreshPrice = useCallback<() => Promise<void>>(() => {
		if (!web3) return Promise.reject();
		return contract?.methods
			.price()
			.call()
			.then((val: any) => {
				console.log('NFT Price wei: ' + val);
				const ethPrice = web3.utils.fromWei(val, 'ether');
				console.log('NFT Price Eth: ' + ethPrice);
				setEthPrice(parseFloat(ethPrice));
			});
	}, [contract, web3]);

	const refreshBaseUri = useCallback<() => Promise<void>>(() => {
		if (!isOwner) return Promise.reject();
		return contract?.methods
			.getBaseURI()
			.call({ from: walletAddress })
			.then((baseUri: string) => {
				setBaseURIValue(baseUri);
			});
	}, [contract, walletAddress, isOwner]);

	const refreshNotRevealedUri = useCallback<() => Promise<void>>(() => {
		return contract?.methods
			.notRevealedURI()
			.call()
			.then((val: string) => {
				setNotRevealedURIValue(val);
			});
	}, [contract]);

	const refreshEthBalance = useCallback<() => Promise<void>>(() => {
		if (!web3) return Promise.reject();

		const address = process.env.REACT_APP_CONTRACT_ADDRESS;
		if (!address) return Promise.reject();
		return web3.eth.getBalance(address).then((value: string) => {
			const ethValue = web3.utils.fromWei(value, 'ether');
			setContractEth(parseFloat(ethValue));
		});
	}, [web3]);

	const refreshTotalSupply = useCallback<() => Promise<void>>(() => {
		return contract?.methods
			.totalSupply()
			.call()
			.then((val: any) => {
				console.log('Supply: ' + val);
				setTotalSupply(parseInt(val));
			});
	}, [contract]);

	const refreshMaxSupply = useCallback<() => Promise<void>>(() => {
		return contract?.methods
			.maxSupply()
			.call()
			.then((val: any) => {
				console.log('Max Supply: ' + val);
				setMaxSupplyValue(parseInt(val));
			});
	}, [contract]);

	const refreshAll = useCallback(async () => {
		console.log('Refresh all');
		setRequestInProgress(true);
		await refreshOwner();
		await refreshTotalSupply();
		await refreshMaxSupply();
		await refreshPrice();
		if (walletAddress) {
			await refreshIsWhitelisted();
			await refreshMyBalance();
		}
		await refreshNotRevealedUri();
		setRequestInProgress(false);
	}, [
		refreshIsWhitelisted,
		refreshMyBalance,
		refreshMaxSupply,
		refreshNotRevealedUri,
		refreshOwner,
		refreshPrice,
		refreshTotalSupply,
		walletAddress,
	]);

	/** Refresh all contract values */
	useEffect(() => {
		if (!web3) return;
		refreshAll();
	}, [web3, refreshAll]);

	// Only owner refresh methods.
	useEffect(() => {
		if (isOwner && web3) {
			refreshBaseUri();
			refreshEthBalance();
		}
	}, [isOwner, web3, refreshBaseUri, refreshEthBalance]);

	const tokenURI = useCallback<(tokenId: number) => Promise<string>>(
		(tokenId: number) => {
			return contract?.methods
				.tokenURI(tokenId)
				.call()
				.then((result: string) => result);
		},
		[contract?.methods]
	);

	const refreshAfterMint = useCallback(async () => {
		await refreshTotalSupply();
		await refreshMyBalance();
		setRequestInProgress(false);
	}, [refreshMyBalance, refreshTotalSupply]);

	const queryUserNFTs = useCallback(async () => {
		if (!contract || !walletAddress) return Promise.resolve([]);

		const tokenIds: number[] = await contract.methods
			.walletOfOwner(walletAddress)
			.call();

		const nfts: NFT[] = [];

		// Todo: Move this method away to an asyn function which can load any users collection data.
		for (let i = 0; i < tokenIds.length; i++) {
			// Fetch the token ID by the users index
			const tokenId = tokenIds[i];

            const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
            const url = `https://api.opensea.io/api/v1/asset/${contractAddress}/${tokenId}/`

			const resp = await fetch(url).catch();
			const json = await resp.json();
			// Replace the image url.
			const image = json?.image_url || '';
            const traits = json.traits;
            console.log(json);
			const metadata = {
				tokenId: json.token_id,
				image,
                traits
			};

			// Add to the list.
			nfts.push({ tokenId, metadata });
		}
		return nfts;
	}, [contract, walletAddress]);

	useEffect(() => {
		queryUserNFTs()
			.then((result) => {
				setUserNFTList(result);
			})
			.catch((err) => console.log(err));
	}, [queryUserNFTs]);

	const mint = useCallback(
		(quantity: number) => {
			if (!walletAddress) return;
			if (!web3) return;
			setRequestInProgress(true);
			const totalCostWei = web3.utils.toWei(String(quantity * ethPrice), 'ether');

			return contract?.methods
				.mint(quantity)
				.send({
					from: walletAddress,
					value: totalCostWei,
				})
				.then(refreshAfterMint)
				.catch(() => {
					setRequestInProgress(false);
				});
		},
		[contract, walletAddress, ethPrice, web3, refreshAfterMint]
	);

	// Admin functions.
	const setPrice = useCallback(
		(price: number) => {
			const weiPrice = web3?.utils.toWei(price.toString(), 'ether');
			return contract?.methods
				.setPrice(weiPrice)
				.send({ from: walletAddress })
				.then(() => {
					refreshPrice();
				});
		},
		[contract, walletAddress, refreshPrice, web3]
	);

	const setBaseURI = useCallback(
		(uri: string) => {
			return contract?.methods
				.setBaseURI(uri)
				.send({ from: walletAddress })
				.then(() => {
					// Update state value.
					setBaseURIValue(uri);
				});
		},
		[contract, walletAddress]
	);

	const setNotRevealedURI = useCallback(
		(uri: string) => {
			console.log(`Set not revealed URI: ${uri}`);
			return contract?.methods
				.setNotRevealedURI(uri)
				.send({ from: walletAddress })
				.then(() => {
					// Refresh Base URI
					setNotRevealedURIValue(uri);
				});
		},
		[contract, walletAddress]
	);

	const setBaseURIExtension = useCallback(
		(ext: string) => {
			return contract?.methods
				.setBaseURIExtension(ext)
				.send({ from: walletAddress })
				.then(() => {
					callContractMethod('baseExtension');
				});
		},
		[contract, walletAddress, callContractMethod]
	);

	const toggleMinting = useCallback(() => {
		return contract?.methods
			.toggleMinting()
			.send({ from: walletAddress })
			.then(() => {
				callContractMethod('mintingActive');
			});
	}, [contract, walletAddress, callContractMethod]);

	const toggleAllowList = useCallback(() => {
		return contract?.methods
			.toggleAllowList()
			.send({ from: walletAddress })
			.then(() => {
				callContractMethod('allowListOnly');
			});
	}, [contract, walletAddress, callContractMethod]);

	const reveal = useCallback(() => {
		return contract?.methods
			.reveal()
			.send({ from: walletAddress })
			.then(() => {
				console.log("NFT's revealed.");
			});
	}, [contract, walletAddress]);

	const setMaxMintAmount = useCallback(
		(amount: number) => {
			return contract?.methods
				.setMaxMintAmount(amount)
				.send({ from: walletAddress })
				.then(() => {
					// Refresh max mint amount
				});
		},
		[contract, walletAddress]
	);

	const setMaxGiftSupply = useCallback(
		(amount: number) => {
			return contract?.methods
				.setMaxGiftSupply(amount)
				.send({ from: walletAddress })
				.then(() => {
					// Refresh
				});
		},
		[contract, walletAddress]
	);

	const setMaxSupply = useCallback(
		async (amount: number) => {
			return contract?.methods
				.setMaxSupply(amount)
				.send({ from: walletAddress })
				.then(() => {
					refreshMaxSupply();
				});
		},
		[contract?.methods, refreshMaxSupply, walletAddress]
	);

	const teamMint = useCallback(
		async (amount: number) => {
			return contract?.methods
				.teamMint(amount)
				.send({ from: walletAddress })
				.then(refreshAll);
		},
		[contract?.methods, walletAddress, refreshAll]
	);

	const setAllowList = useCallback(
		async (addressList: string[], enabled: boolean) => {
			return contract?.methods
				.setAllowList(addressList, enabled)
				.send({ from: walletAddress });
		},
		[contract?.methods, walletAddress]
	);

	const gift = useCallback(
		async (addressList: string[]) => {
			return contract?.methods.gift(addressList).send({ from: walletAddress });
		},
		[contract?.methods, walletAddress]
	);

	const withdraw = useCallback(() => {
		return contract?.methods
			.withdraw()
			.send({ from: walletAddress })
			.then(() => {
				// Todo: Refresh balances.
				refreshEthBalance();
			});
	}, [walletAddress, contract, refreshEthBalance]);

	const api = useMemo<ContractContext>(() => {
		return {
			contractValues,
			totalMinted: totalSupply,
			mintPrice: ethPrice,
			maxSupply: maxSupply,
			isOwner,
			isAllowListed: isAllowListed,
			userOwnedTokens,
			userNFTList,
			contractEth,
			baseURI,
			notRevealedURI,
			requestInProgress,
			tokenURI,
			mint,
			setPrice,
			setBaseURI,
			setNotRevealedURI,
			setBaseURIExtension,
			toggleMinting,
			toggleAllowList,
			reveal,
			setMaxMintAmount,
			setMaxGiftSupply,
			setMaxSupply,
			teamMint,
			setAllowList,
			gift,
			withdraw,
		};
	}, [
		contractValues,
		totalSupply,
		maxSupply,
		ethPrice,
		isOwner,
		isAllowListed,
		userOwnedTokens,
		userNFTList,
		contractEth,
		baseURI,
		notRevealedURI,
		requestInProgress,
		tokenURI,
		mint,
		setPrice,
		setBaseURI,
		setNotRevealedURI,
		setBaseURIExtension,
		toggleMinting,
		toggleAllowList,
		reveal,
		setMaxMintAmount,
		setMaxGiftSupply,
		setMaxSupply,
		teamMint,
		setAllowList,
		gift,
		withdraw,
	]);

	return <contractContext.Provider value={api}>{children}</contractContext.Provider>;
};

export const useContract = () => React.useContext(contractContext);

export default ContractProvider;
