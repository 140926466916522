import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { useWallet } from '../providers/WalletProvider';
import { isMobile } from 'react-device-detect';

import Metamask from '../img/Metamask-fox.svg';
import WalletConnectSVG from '../img/WalletConnect.svg';
import { ConnectType } from '../connectors/connectors';
import Modal from '@mui/material/Modal';
import { Paper } from '@mui/material';

const ConnectButton = styled(Button)({
	borderRadius: 10,
	color: 'white',
	fontFamily: 'ArchitectsDaughter',
	justifyContent: 'space-between',
});

const WalletConnectButton = styled(ConnectButton)(({ theme }) => ({
	marginTop: theme.spacing(2),
	fontFamily: 'ArchitectsDaughter',
}));

const WalletIcon = styled('img')(({ theme }) => ({
	width: 32,
	height: 32,
}));

const style: any = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '1px solid #000',
	borderRadius: '20px',
	boxShadow: 24,
	p: 4,
};

const ModelContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
}));

const WalletConnect = () => {
	const {
		connect,
		disconnect,
		walletAddress,
		connectorType,
		showConnect,
		closeConnect,
		connectShow,
	} = useWallet();
	const handleOpen = () => showConnect();
	const handleClose = () => closeConnect();

	const onClickConnect = () => {
		if (walletAddress) {
			disconnect();
		} else {
			handleOpen();
		}
	};

	const buttonText = useMemo(() => {
		if (!walletAddress) return 'Connect Wallet';
		return `${walletAddress.substr(0, 6)} .... Disconnect`;
	}, [walletAddress]);

	const connectedIcon = useMemo(() => {
		if (connectorType === ConnectType.Injected) {
			return Metamask;
		}
		if (connectorType === ConnectType.WalletConnect) {
			return WalletConnectSVG;
		}
	}, [connectorType]);

	const connectTo = (type: ConnectType) => () => {
		connect(type).then((connected) => {
			// Yes we are connected.
			if (connected) {
				handleClose();
			}
		});
	};

	return (
		<div>
			<ConnectButton
				startIcon={connectedIcon && <WalletIcon src={connectedIcon} />}
				variant="outlined"
				onClick={onClickConnect}
			>
				{buttonText}
			</ConnectButton>
			<Modal
				open={connectShow}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper sx={style}>
					<ModelContent>
						{!isMobile && (
							<ConnectButton
								variant="outlined"
								onClick={connectTo(ConnectType.Injected)}
								endIcon={<WalletIcon src={Metamask} />}
							>
								MetaMask Browser
							</ConnectButton>
						)}

						<WalletConnectButton
							onClick={connectTo(ConnectType.WalletConnect)}
							variant="outlined"
							endIcon={<WalletIcon src={WalletConnectSVG} />}
						>
							WalletConnect
						</WalletConnectButton>
					</ModelContent>
				</Paper>
			</Modal>
		</div>
	);
};

export default WalletConnect;

