import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Container = styled('div', { name: 'NavMenu' })(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
}));

const MenuItemContainer = styled('div', { name: 'MenuItem' })(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	height: '100%',
}));

const MenuText = styled(Typography)(({ theme }) => ({
	'&&:hover': {
		color: theme.palette.primary.main,
	},
	transition: 'all .15s ease-out',
}));

interface MenuItemProps {
	text: string;
	icon?: React.ReactElement;
	goTo?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ text, icon, goTo }) => {
	const navigate = useNavigate();
	const clickNav = () => {
		if (goTo) {
			navigate(goTo);
		}
	};
	return (
		<MenuItemContainer onClick={clickNav} sx={{ marginLeft: 4 }}>
			{icon && icon}
			<MenuText noWrap>{text}</MenuText>
		</MenuItemContainer>
	);
};

const Home = () => {
	const navigate = useNavigate();
	const clickNav = () => {
		navigate('/');
	};
	return (
		<MenuItemContainer onClick={clickNav}>
			<MenuText variant="h4" sx={{ fontFamily: 'ArchitectsDaughter' }} noWrap>
				{'Girl Vibes'}
			</MenuText>
		</MenuItemContainer>
	);
};

const NavMenu = () => {
	return (
		<Container>
			<Home />
			<MenuItem text="Good Vibes" goTo="goodvibes" />
		</Container>
	);
};

export default NavMenu;

